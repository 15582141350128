<template>
    <div class="grid">
        <div class="col-12">
            <ProgressBar v-if="loading" mode="indeterminate" style="height: .3em"/>
            <div class="card">
                <Toast />
                <div class="grid">
                    <div class="col-12">
                        <FullCalendar class="full-calendar-custom-class" :options="calendarOptions">
                            <template v-slot:eventContent="arg">
                                <Card :style="'width: 100%;background-color: ' + arg.event.extendedProps.cardColor + ';'">
                                    <template #title> {{ arg.event.title }} </template>
                                    <template #content>
                                        <p>
                                            {{ arg.event.extendedProps.subtitle }}
                                        </p>
                                    </template>
                                </Card>
                            </template>
                        </FullCalendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import pt from '@fullcalendar/core/locales/pt';
import AgendaReservaHorarioService from './services/AgendaReservaHorarioService';

export default {
    data() {
        return {
            loading: false,
            events: [],
            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
                initialView: 'dayGridMonth',
                locale: pt,
                editable: false,
                selectable: false,
                selectMirror: false,
                dayMaxEvents: true,
                initialDate: new Date(),
                headerToolbar: {
                    left: 'title',
                    right: 'prev,today,next'
                },
                events: this.eventsFn,
                eventClick: (events) => this.eventClick(events?.event),
                dateClick: this.dateClick,
                select: this.select,
                loading: this.loadingCallback
            }
        };
    },

    components: { FullCalendar },

    created() {
        this.$service = new AgendaReservaHorarioService();
    },

    methods: {
        loadingCallback(loading) {
            this.loading = loading;
        },
        async eventsFn(info, successCallback, failureCallback) {
            try {
                const events = await this.loadEvents(info.start, info.end);
                successCallback(events);
            } catch (error) {
                failureCallback(error)
            }
        },
        async loadEvents(start, end) {
            this.events = [];
            const { data } = await this.$service.getEventosCalendario({
                dataHoraInicial: start,
                dataHoraFinal: end
            });
            this.events = data;
            return this.events;
        },
        async eventClick(event) {
            this.$router.push('/reserva-horario/dia/' + event.startStr);
        }
    }
};
</script>

<style scoped lang="scss">
.days,
.week-days {
    display: grid;
    grid-template-columns: repeat(7, 50px);
    grid-column: 7;
}

:deep([data-v-app]) {
    width: 100%;
    margin: 10px;
}
</style>
